import styled from '@emotion/styled';
import { Container } from '@layouts';
import discovery from '@assets/process-page/discovery.svg';
import developement from '@assets/process-page/developement.svg';
import productlaunch from '@assets/process-page/productlaunch.svg';
import roadmap from '@assets/process-page/roadmap.svg';
import support from '@assets/process-page/support.svg';
import testing from '@assets/process-page/testing.svg';

export const SectionContentWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  padding: 0 1.5rem;
  &.first {
    margin-top: 0;
  }
`;

export const Title = styled.h1`
  text-align: center;
`;

export const SubContent = styled.p`
  text-align: center;
  font-size: 20px;
`;

export const Separator = styled.div`
  width: 80px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.primary.base};
  transform: rotate(90deg);
  top: 40px;
  position: relative;
  &.last {
    display: none;
  }
`;

export const ProcessStep = styled.ul`
  margin: 0;
  display: flex;
  flex-flow: column;
  margin: 20px 0;
  max-width: 600px;
`;

export const StepItem = styled.div`
  font-size: 18px;
  padding-bottom: 20px;
  position: relative;
  padding-left: 25px;
  &:before {
    content: '';
    display: inline-flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary.base};
    margin-right: 20px;
    position: absolute;
    left: 0px;
    top: 5px;
  }
  &:not(.last):after {
    content: '';
    display: inline-flex;
    width: 2px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.primary.base};
    margin-right: 20px;
    position: absolute;
    left: 5px;
    top: 15px;
  }
`;

export const StepIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 20px 0;
  background-color: ${(props) => props.theme.colors.primary.base};
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary.base},
    inset 0 0 0 5px ${(props) => props.theme.colors.white.base};
  svg {
    width: 50px;
    height: 50px;
    fill: white;
  }
`;

export const DiscoverIcon = styled(discovery)``;

export const DevelopementIcon = styled(developement)``;

export const ProductLaunchIcon = styled(productlaunch)``;

export const RoadMapIcon = styled(roadmap)``;

export const SupportIcon = styled(support)``;

export const TestingIcon = styled(testing)``;
