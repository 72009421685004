import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Layout } from 'layouts';

import ProcessSection from '@components/processSection';
import Heading from '@components/pageHeading';
import { PageHeader } from '@components';

import HeaderIcon from '@static/icons/process.svg';
import headerImage from '@static/header/andres-urena-qSw5XKtUyus-unsplash.jpg';

import ProcessPageContent from '@constants/process';

class ProcessPage extends Component {
  render() {
    return (
      <Layout>
        <Helmet title={'Process page'} />
        <PageHeader
          image={headerImage}
          title={'Process'}
          icon={HeaderIcon}
        />
        <Heading
          title="Here's how we do it"
          overview="Delivering user-driven mobile apps for startup, enterprises and agencies spot on, everytime!"
        />
        {ProcessPageContent.map((item, i) => (
          <ProcessSection
            key={i}
            title={item.title}
            content={item.content}
            steps={item.steps}
            type={item.type}
            className={ProcessPageContent.length === i + 1 ? 'last' : ''}
            sectionClass={i === 0 ? 'first' : ''}
          />
        ))}
      </Layout>
    );
  }
}

export default ProcessPage;
