const ProcessPage = [
  {
    type: 'discovery',
    title: 'Discovery and Design',
    content:
      'This guided phase is centered towards understanding requirements ' +
      'with unparelled technical and digital experiences to reduce risks and ' +
      'put project into perspective quickly',
    steps: [
      'We initiate with a Discovery meeting with the client',
      'We do a User Research to ensure great user experience',
      'Our UX experts create WireFrames for your product Idea',
      'Creative designers Design Showcase with Mock Screens',
      'Client gets a Product Spec Sheet + Business Proposal',
    ],
  },
  {
    type: 'roadmap',
    title: 'Product Roadmap and Sprints planning',
    content:
      'We put your entire project into a robust process where it can be clearly invisioned,' +
      'tested, built to scale and perform',
    steps: [
      'We Kick off a Meeting  with Scrum team and our clients',
      'Our Product Manager defines the product roadmap for your project',
      'The estimated Development cycles are converted into sprints of 1-2 weeks',
      'Clients get access to complete product plan and a timelime draft',
    ],
  },
  {
    type: 'development',
    title: 'Development and Iteration',
    content:
      'Our Agile process drive faster improvements and ensure your next killer ' +
      'feature is only a sprint away',
    steps: [
      'We adhere to Daily Standup meetings',
      'We do a Sprint Wide Demonstration and identify blockages',
      'Sprint Retrospective and Toasts are done after each Sprint',
      'All our Clients get Sprint highlights document after each sprint',
      'Securely distribute the in-development version of the app to testers,' +
        ' clients, and other developers',
      'Use tools to notify users of new builds, provide crash reporting,' +
        'and ensure only approved testers have access to your app',
      'We make sure everyone is up to speed on progress.',
    ],
  },
  {
    type: 'testing',
    title: 'Testing & QA',
    content:
      'Each feature goes through rigourous and ruthless testing, compliance' +
      'checks to deliver zero fault user experience',
    steps: [
      'Functional Testing, whereinto verify if the features are working ' +
        'exactly the way they are required to',
      'Fit and Finish Testing - . Designers review each feature and ensure ' +
        'that their vision was implemented as described in the design',
      'Regression Testing to repeatedly test all the features in every phase',
      'Device-Specific Testing, as the name suggests, involves trying out the ' +
        'app on various screen sizes and with as many different OS and ' +
        'device combinations as possible to ensure the versatility of the app',
      'User Acceptance Testing to get your app tested by the target users ' +
        'and obtain feedback from them about every feature',
    ],
  },
  {
    type: 'launch',
    title: 'Launch and Deployment',
    content:
      'Your product is all decked for a successful launch. We ensure seamless ' +
      'production and a Perfect delivery!',
    steps: [
      'We finalize build and conduct a thorough app store optimization',
      'We make sure your analytics setup is intact',
      'We create custom-made plans to suit your deployment and delivery ' +
        'requirements',
      'We ensure appstore approvals and go for a Kickass Deployment',
      'After you give a go ahead we deploy your app to App Store, ' +
        'Google Play or other public app stores ',
    ],
  },
  {
    type: 'support',
    title: 'Support and Maintenance',
    content:
      // eslint-disable-next-line prettier/prettier
      'Our job doesn\'t end here, we provide Post release support ' +
      'and maintenance',
    steps: [
      'Continuous monitoring is done to join any loose ends if any',
      'Still unsure of your product ? we are open for a Maintenance ' +
        'contract for you...',
    ],
  },
];

export default ProcessPage;
