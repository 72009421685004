import React from 'react';
import {
  SectionContentWrapper,
  Title,
  SubContent,
  Separator,
  StepIconWrapper,
  ProcessStep,
  StepItem,
  DiscoverIcon,
  DevelopementIcon,
  ProductLaunchIcon,
  RoadMapIcon,
  SupportIcon,
  TestingIcon,
} from './style';

const SectionContent = props => {
  const getIcon = type => {
    switch (type) {
      case 'discovery':
        return <DiscoverIcon />;
      case 'roadmap':
        return <RoadMapIcon />;
      case 'development':
        return <DevelopementIcon />;
      case 'testing':
        return <TestingIcon />;
      case 'launch':
        return <ProductLaunchIcon />;
      case 'support':
        return <SupportIcon />;
    }
  };
  return (
    <SectionContentWrapper className={props.sectionClass}>
      <StepIconWrapper>{getIcon(props.type)}</StepIconWrapper>
      {props.title ? <Title>{props.title}</Title> : null}
      {props.content ? <SubContent>{props.content}</SubContent> : null}
      <ProcessStep>
        {props.steps.map((item, i) => {
          if (props.steps.length === i + 1) {
            return (
              <StepItem key={i} className="last">
                {item}
              </StepItem>
            );
          } else {
            return <StepItem key={i}>{item}</StepItem>;
          }
        })}
      </ProcessStep>
      <Separator className={props.className} />
    </SectionContentWrapper>
  );
};

export default SectionContent;
